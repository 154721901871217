import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import {pageGutters} from "../css/layout.module.css"
import Postcard from 'src/components/postcard'
import PostCollection from 'src/components/post-collection'
import * as styles from 'src/css/blog-index.module.css'
import clsx from 'clsx'

const BlogIndex = ({data, pageContext}) => {
  const {heroArticles, articles, latestNews, strapiBlogIndex: {seo}} = data
  const {country} = pageContext || {}

  return (
    <Layout seo={seo} country={country}>
      <div className={clsx(styles.blogIndex, pageGutters)}>
        <h1>Blog</h1>
        <div className={styles.main}>
          <div className={styles.articles}>
            <Postcard post={heroArticles.nodes[0]} size="xl" className={styles.heroArticle} country={country} imgLoading="eager" />
            <PostCollection posts={articles.nodes} country={country} grid size="lg" />
          </div>
          <div className={styles.news}>
            <h2>Latest News</h2>
            <PostCollection posts={latestNews.nodes} country={country} column compact size="md" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    strapiBlogIndex {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    heroArticles: allStrapiArticle(
      filter: {postType: {slug: {eq: "articles"}}}
      sort: {fields: publishedAt, order: DESC} 
      limit: 1
    ) {
      nodes {
        slug
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 996
                height: 559
                transformOptions: {cropFocus: CENTER}
                placeholder: TRACED_SVG
              ) 
            }
          }
        }
        author {
          name
        }
      }
    }
    articles: allStrapiArticle(
      filter: {postType: {slug: {eq: "articles"}}}
      sort: {fields: publishedAt, order: DESC}
      skip: 1
    ) {
      nodes {
        ...Postcard
      }
    }
    latestNews: allStrapiArticle(
      filter: {postType: {slug: {eq: "news"}}}
      sort: {fields: publishedAt, order: DESC}
    ) {
      nodes {
        slug
        title
        excerpt
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 215
                height: 215
                transformOptions: {cropFocus: CENTER}
                placeholder: TRACED_SVG
              ) 
            }
          }
        }
        author {
          name
        }
      }
    }
  }
`;

export default BlogIndex;
